<script lang="ts" setup>
import { Link } from '~/types'

defineProps<{
  link: Link
  dim: boolean
}>()

const item = ref()
const isHovered = useElementHover(item)
</script>

<template>
  <li ref="item" :class="['item', { dim }]">
    <slot />
    <ButtonIcon :class="[{ 'is-hovered': isHovered }]" />
    <NuxtLink :to="link.url" :title="link.text ?? ''" :target="link.target ?? '_self'" class="link" />
  </li>
</template>

<style lang="scss" scoped>
.item {
  position: relative;

  display: grid;
  grid-template-columns: 100%;
  align-items: center;
  gap: var(--gap);

  border-bottom: 1px solid var(--c-line-light);

  @include fluid(padding-block, 30px, 38px);
  @include transitions(opacity);
  &.dim:not(:hover) {
    opacity: 0.5;
  }

  @include breakpoint(tl) {
    grid-template-columns: 1fr auto;
  }
}

.link {
  @include fill;
}
</style>
